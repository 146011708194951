@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Bold.woff2') format('woff2'),
        url('../fonts/Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-BlackItalic.woff2') format('woff2'),
        url('../fonts/Roboto-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Black.woff2') format('woff2'),
        url('../fonts/Roboto-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Italic.woff2') format('woff2'),
        url('../fonts/Roboto-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-BoldItalic.woff2') format('woff2'),
        url('../fonts/Roboto-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Light.woff2') format('woff2'),
        url('../fonts/Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-LightItalic.woff2') format('woff2'),
        url('../fonts/Roboto-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Medium.woff2') format('woff2'),
        url('../fonts/Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Regular.woff2') format('woff2'),
        url('../fonts/Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-MediumItalic.woff2') format('woff2'),
        url('../fonts/Roboto-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Swis721 Cn BT';
    src: url('../fonts/Swiss721BT-BoldCondensedItalic.woff2') format('woff2'),
        url('../fonts/Swiss721BT-BoldCondensedItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Swis721 Blk BT';
    src: url('../fonts/Swiss721BT-Black.woff2') format('woff2'),
        url('../fonts/Swiss721BT-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Swis721 Cn BT';
    src: url('../fonts/Swiss721BT-RomanCondensed.woff2') format('woff2'),
        url('../fonts/Swiss721BT-RomanCondensed.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-Thin.woff2') format('woff2'),
        url('../fonts/Roboto-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Swis721 Cn BT';
    src: url('../fonts/Swiss721BT-ItalicCondensed.woff2') format('woff2'),
        url('../fonts/Swiss721BT-ItalicCondensed.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Roboto';
    src: url('../fonts/Roboto-ThinItalic.woff2') format('woff2'),
        url('../fonts/Roboto-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Swis721 Cn BT';
    src: url('../fonts/Swiss721BT-BoldCondensed.woff2') format('woff2'),
        url('../fonts/Swiss721BT-BoldCondensed.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
body {
    width: 1200px;
    max-width: 1200px;
    margin: 0px auto;
    display: block;
    word-spacing: 2px;
    /* overflow-x: hidden; */
}

.main-logo {
    /* height: 110px; */
    height: 85px;
    display: inline-block;
}
.mobnav-height {
    /* height: 110px; */
    height: 85px;
}
.main-navbar .nav-item {
    padding-left: 15px;
    padding-right: 15px;
}
.main-navbar {
    background-color: black;
    position: fixed;
    width: 1200px;
    z-index: 1;
}
.main-navbar .nav-link {
    color: #ffff00;
    font-family: 'Roboto';
    /* font-size: 22px; */
    font-size: 19px;
    text-transform: uppercase;
    padding-right: 30px !important;
    padding-left: 30px !important;
}
.active{
    /* background-color: red; */
    color: #c40632 !important;
    border-bottom: 3px solid #c40632;
}
.navbar {
    width: 88%;
    display: inline-block;
}
.navbar-nav {
    justify-content: center;
}
.section1-bg {
    background-image: url('./../images/section1bg.png');
    background-size: cover;
    /* height: 100vh; */
    margin: 1% 0%;
    padding: 3% 0%;
    background-repeat: no-repeat;
    background-position: left center;
}
.section1-can {
    /* width: 40%; */
    width: 32%;
    margin: 0px auto;
    display: block;
    position: relative;
    left: 12%;
}
.protect-yourself-head {
    color: #fff;
    font-size: 60px;
    font-family: 'Swis721 Cn BT';
    font-weight: bold;
    /* margin-top: 62%; */
    margin-top: 50%;
    margin-left: -11%;
    line-height: 60px;
    margin-bottom: 5%;
}
.buy-btn {
    background-color: #ffff00;
    color: #000;
    border-radius: 50px;
    font-family: 'Roboto';
    font-size: 18px;
    text-transform: uppercase;
    padding: 11px 26px;
    text-decoration: none;
    margin-left: -11%;
    box-shadow: 3px 2px 0 0 #000;
    font-weight: 500;
}
.phoneicon-text a {
    color: unset;
    text-decoration: none;
}
.section2bg {
    background-image: url('./../images/section2bg.png');
    /* height: 862px; */
    height: 799px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}
.section2-box {
    position: absolute;    
    width: 100%;
    bottom: 50px;
}
.section2-content {
    padding: 10px 15px;
    background-color: black;
    margin: 0px auto;
    display: block;
    width: 50%;
    text-align: center;
}
.box-head {
    color: #fff;
    font-family: 'Roboto';
    font-size: 34px;
    margin-bottom: 0px;
}
.box-text {
    color: #fff;
    font-size: 15px;
    font-family: 'Roboto';
    word-spacing: 0px;
}
.section3bg {
    padding: 8% 0% 5%;
}
.section3bg-img img {
    width: 478px;
}
.section3bg-img {
    float: right;
    margin-right: -115px;
}
.section-text {
    margin-left: 30px;
    margin-top: 25%;
}
.section-text a {
    margin-left: 0px;
    position: relative;
    top: 25px;
}
.section-text p {
    font-size: 19px;
    font-family: 'Roboto';
    color: #000000;
    line-height: 24px;
}
.compare-bg {
    background-image: url('./../images/compare-banner.png');
    height: 574px;
    background-size: cover;
    padding-top: 25%;
}
.compare-text-box {
    text-align: center;
}
.uptext {
    color: #fff;
    font-size: 18px;
    font-family: 'Roboto';
    line-height: 24px;
}
.downtext {
    color: #ffed00;
    font-weight: bold;
    font-family: 'Roboto';
    font-size: 18px;
    line-height: 24px;
}
.greybg {
    background-image: url('./../images/greybg.png');
    /* height: 417px; */
    background-size: cover;
    margin-top: 8%;
    padding-bottom: 6%;
}
.grey-row {
    width: 80%;
    margin: 0px auto;
    display: block;
    position: relative;
    bottom: 15px;
}
.bag-img {
    width: 80%;
}
.greysection {
    padding-left: 5%;
}
.grey-textarea {
    width: 86%;
    margin: 0px auto;
    display: block;
    margin-top: 3%;
}
.grey-head {
    color: #000;
    font-size: 35px;
    font-weight: bold;
    font-family: 'Roboto';
    line-height: 45px;
    margin-bottom: 0px;
}
.grey-text {
    color: #000;
    font-size: 17px;
    font-family: 'Roboto';
    margin-bottom: 0px;
    line-height: 22px;
    margin-top: 2px;
    margin-left: -25px;
}
.disclaimer-row {
    width: 100%;
}
.caution-div {
    /* background-color: #e51a4b; */
    background-color: #da0438;
    padding: 35px 0px;
}
.disclaimer-div {
    /* background-color: #ff6600; */
    background-color: #db5201;
    padding: 35px 0px;
}
.caution-head {
    font-family: 'Roboto';
    color: #fff;
    font-weight: 500;
    text-align: center;
    font-size: 26px;
    margin-bottom: 0px;
}
.caution-text {
    font-family: 'Roboto';
    color: #fff;
    font-weight: 300;
    text-align: center;
    font-size: 14px;
    margin-bottom: 0px;
    margin-top: 10px;
}
.caution-text span {
    color: #ffff00;
}
.disclaimer-row .row {
    margin-left: 0px;
    margin-right: 0px;
}
footer {
    background-color: #000;
    padding: 7% 0%;
}
.upl-logo {
    width: 93px;
    margin: 0px auto;
    display: block;
}
.footer-text {
    font-family: 'Roboto';
    color: #fff;
    font-weight: 300;
    text-align: center;
    font-size: 17px;
    margin-bottom: 0px;
    margin-top: 40px;
}
.footer-text span.uptitle {
    font-size: 24px;
}
.usage-section1bg {
    /* background-image: url('./../images/usage-section1bg.png'); */
    background-image: url('./../images/newusagebg.png');
    background-size: cover;
    height: 694px;
    background-position: bottom center;
    margin: 1% 0%;
}
.usage-section1-img img {
    position: relative;
    bottom: 10px;
    right: 70px;
    float: right;
}
.usage-section1-head {
    font-size: 35px;
    font-family: 'Roboto';
    color: #fff;
    margin-top: 37%;
    font-weight: 300;
    margin-left: -8%;
}
.usage-section1-head span {
    font-weight: bold;
}
.usage-section1-text {
    font-size: 17px;
    font-family: 'Roboto';
    color: #fff;
    margin-top: 4%;
    margin-left: -8%;
    font-weight: 300;
    line-height: 22px;
}
.usage-section2bg {
    background-color: #fecc00;
    position: relative;
    padding-top: 6%;
    padding-bottom: 14%;
    border-radius: 15px;
}
.usage-smallcan-img {
    position: absolute;
    width: 100%;
    bottom: 0px;
}
.usage-smallcan-img img {
    margin: 0px auto;
    display: block;
}
.user-guidance-head {
    font-size: 35px;
    font-family: 'Roboto';
    color: #000;
    text-align: center;
    font-weight: normal;
}
.user-guidance-head span {
    font-weight: bold;
}
.guidance-row {
    width: 60%;
    margin: 0px auto;
    display: block;
}
.icon-text {
    color: #000;
    font-family: 'Roboto';
    font-weight: 300;
    font-size: 17px;
    line-height: 19px;
    position: relative;
    left: 45px;
    top: 7px;
    word-spacing: 1px;
}
.usage-midbannerbg {
    background-image: url('./../images/usage-midbanner.png');
    background-size: cover;
    height: 800px;
    margin: 1% 0%;
    position: relative;
}
.usage-midbanner-div {
    position: absolute;
    width: 100%;
    bottom: 7%;
    left: 6%;
}
.usage-blackbox {
    background-color: rgba(0, 0, 0, 0.6);
    padding: 20px 0px 20px 30px;
    width: 40%;
}
.usage-blackbox-head {
    font-size: 42px;
    font-family: 'Roboto';
    color: #fff;
    font-weight: 300;
}
.usage-blackbox-head span {
    font-weight: bold;
}
.usage-blackbox-text {
    font-size: 17px;
    font-family: 'Roboto';
    color: #fff;
    font-weight: 300;
    line-height: 21px;
}
.featurebg {
    background-color: #fecc00;
    padding: 7% 0%;
    margin-bottom: 2%;
    border-radius: 15px;
}
.feature-row {
    width: 70%;
    margin: 0px auto;
    display: block;
}
.featureimg {
    width: 100%;
}
.featurehead {
    color: #000;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 0px;
    line-height: 30px;
}
.feature-width-set {
    width: 85%;
    margin: 0px auto;
    display: block;
    height: 100%;
}
.featuretext {
    color: #000;
    font-family: 'Roboto';
    font-size: 17px;
    line-height: 22px;
    margin-top: 10px;
}
.align-middle {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.right-align p {
    text-align: right;
}
.faq-page-head {
    font-size: 40px;
    color: #000;
    font-family: 'Roboto';
    font-weight: 500;
    text-align: center;
    margin: 5% 0% 2%;
}
.faq-div {
    margin-bottom: 4%;
}
.accordion-button {
    background-color: #ebebeb;
    color: #000;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 17px;
    box-shadow: 0px;
}
.accordion-button:not(.collapsed) {
    background-color: #ebebeb;
    color: #000;
    font-family: 'Roboto';
    font-weight: 500;
    font-size: 17px;
}
.accordion-body {
    background-color: #f5f5f5;
    font-size: 17px;
    color: #000;
    font-family: 'Roboto';
    font-weight: 300;
    padding: 10px 10px 20px;
}
.accordion-item {
    border-bottom: 2px solid #959595;
}
.accordion-item:last-child {
    border-bottom: 0px;
}
.accordion-button::after {
    background-image: url('./../images/downarrow.png');
    width: 14px;
    height: 13px;
    background-size: cover;
}
.accordion-button:not(.collapsed)::after {
    background-image: url('./../images/downarrow.png');
}
.accordion-item:first-of-type {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}
.enquiry-bg {
    background-color: #f4f4f4;
    padding: 7% 0%;
    margin: 1% 0%;
}
.enquiry-righ-brd {
    border-right: 2px solid #959595;
}
.enquiry-head {
    font-size: 26px;
    color: #000;
    font-family: 'Roboto';
    font-weight: normal;
    margin-left: 134px;
    line-height: 35px;
}
.enquiry-head span {
    font-weight: bold;
}
.enquiry-info-div {
    margin-left: 134px;
    margin-top: 30px;
}
.phoneicon {
    width: 34px;
}
.phoneicon-text {
    font-size: 16px;
    color: #000;
    font-family: 'Roboto';
    font-weight: normal;
    margin-left: -30px;
    margin-bottom: 0px;
    position: relative;
    top: 5px;
}
.follow-head {
    font-size: 15px;
    color: #000;
    font-family: 'Roboto';
    font-weight: normal;
    margin-bottom: 0px;
    margin-top: 80px;
}
.fb-icon {
    margin-right: 15px;
}
.fb-div {
    margin-top: 10px;
}
.form-div {
    margin-left: 134px;
    width: 55%;
}
.enquiry-input {
    background-color: #f9c5d2;
    border: 1px solid #f9c5d2;
    border-radius: 50px;
    height: 42px;
}
::placeholder {
    font-size: 14px;
    color: #000 !important;
    font-family: 'Roboto';
    font-weight: normal;
    position: relative;
    left: 10px;
}
.form-control:focus {
    background-color: #f9c5d2;
    border: 1px solid #f9c5d2;
    box-shadow: none;
}
.no-padd-right {
    padding-right: 0px;
}
.textarea {
    height: unset;
    border-radius: 10px;
}
.enquire-btn {
    width: 100%;
    height: 42px;
    font-size: 15px;
    color: #fff;
    font-family: 'Roboto';
    font-weight: normal;
    text-align: center;
    background-color: #e51a4b;
    border: 1px solid #e51a4b;
    border-radius: 50px;
}
.manatory {
    font-size: 12px;
    color: #000;
    font-family: 'Roboto';
    font-weight: normal;
    margin-bottom: 2px;
    float: right;
}
.manatory span {
    color: #e51a4b;
}
.buy-head-img {
    width: 380px;
    float: right;
    position: relative;
    right: -50px;
}
.buy-section1 {
    padding: 7% 0%;
}
.buy-head-img-div {
    width: 100%;
    float: right;
}
.Buy-btn-div {
    float: right;
    margin-top: 15px;
}
.Buy-btn-div .buy-btn {
    padding: 11px 140px;
}
.buy-section1-head {
    font-size: 27px;
    color: #000;
    font-family: 'Roboto';
    font-weight: normal;
    margin-bottom: 0px;
    margin-top: 8%;
    line-height: 32px;
}
.buy-section1-info {
    margin-top: 5%;
    font-size: 17px;
    color: #000;
    font-family: 'Roboto';
    font-weight: normal;
    margin-bottom: 0px;
    line-height: 22px;
}
.buy-section1-desc {
    margin-top: 5%;
    font-size: 14px;
    color: #000;
    font-family: 'Roboto';
    font-weight: normal;
    margin-bottom: 0px;
    line-height: 22px;
}
.amazondiv {
    margin-top: 10px;
}
.amazonimg {
    width: 92px;
    margin-bottom: 10px;
    margin-right: 10px;
}
.avaliable-div {
    margin-top: 100px;
}
.buy-section1-info-div {
    margin-left: 3%;
}
.buy-section2bg {
    background-color: #fecc00;
    padding: 8% 8% 6%;
    border-radius: 20px;
}
.buy-feature {
    width: 80%;
    margin: 0px auto;
    display: block;
}
.buy-feature-text {
    margin-top: 8%;
    font-size: 17px;
    color: #000;
    font-family: 'Roboto';
    font-weight: bold;
    margin-bottom: 0px;
    text-align: center;
}
.feature-margin-top {
    margin-top: 6%;
}
.video-bg {
    background-image: url('./../images/buy-video.png');
    background-size: cover;
    height: 525px;
    background-position: bottom center;
    margin: 1% 0%;
}
.buy-subhead {
    font-size: 26px;
    color: #000;
    font-family: 'Roboto';
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 5%;
}
.buy-subhead-text {
    font-size: 18px;
    color: #000;
    font-family: 'Roboto';
    font-weight: normal;
    margin-bottom: 0px;
    margin-top: 1.5%;
}
.usediv1 {
    width: 15%;
    display: inline-block;
}
.usediv2 {
    width: 28%;
    display: inline-block;
}
.usediv3 {
    width: 29%;
    display: inline-block;
}
.usediv4 {
    width: 27%;
    display: inline-block;
}
.icon01-text {
    font-size: 18px;
    color: #000;
    font-family: 'Roboto';
    font-weight: 300;
    margin-bottom: 0px;
    line-height: 22px;
    position: relative;
    top: 30px;
    word-spacing: 0px;
}
.no-padd {
    padding: 0px;
}
.icon01 {
    position: relative;
    right: 10px;
}
.buy-usediv-row {
    margin-bottom: 10%;
    margin-top: 15px;
}
.icon02-text {
    left: 65px;
}
.icon02 {
    bottom: 15px;
}
.icon04 {
    bottom: 20px;
}
.buy-midboxbg {
    background-color: #ff9000;
    padding: 4% 3% 3%;
}
.feature01-text {
    color: #fff;
    font-size: 17px;
    font-family: 'Roboto';
    font-weight: 300;
    margin-bottom: 0px;
    line-height: 22px;
    text-align: center;
    margin-top: 15px;
}
.feature01 {
    margin: 0px auto;
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.image-div {
    height: 80px;    
}
.storage-text {
    color: #000;
    font-size: 15px;
    font-family: 'Roboto';
    font-weight: 300;
    margin-bottom: 0px;
    line-height: 18px;
    text-align: center;
    margin-top: 10px;
}
.storage1 {
    margin: 0px auto;
    display: block;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}
.storage-img-div {
    height: 50px;
}
.storagerow {
    margin-top: 20px;
}
.storagediv {
    margin-bottom: 3%;
}
.storage-head {
    margin-top: 2%;
}
.buy-btn:hover {
    background-color: #e51a4b;
    color: #fff;
}
.enquire-btn:hover {
    background-color: #f9c5d2;
}
.accordion-button:focus {
    box-shadow: unset;
}
.desknewnav .main-logo {
    height: 60px;
}
.desknewnav.main-navbar .nav-link {
    font-size: 16px;
    padding-right: 20px !important;
    padding-left: 20px !important;
} 
.desknewnav.main-navbar .navbar {
    padding: 0px;
}
/* .desknewnav a, .desknewnav nav {
    display: none;
} */
.modal {
    overflow-y: auto;
}

.modal-open {
    overflow: auto;
}

.modal-open[style] {
    padding-right: 0px !important;
}
.modal-body .buy-section1-desc {
    margin-top: 0px;
    text-align-last: center;
}

html {
    overflow-y: scroll;
  }
  .featurebg-main {
      background-image: url('./../images/featurebgmain.png');
      background-size: cover;
      background-position: center center;
      height: 610px;
      margin: 1% 0%;
      padding-top: 0%;
      position: relative;
  }
  .featuretopleft {
      /* width: 60%; */
      float: right;
      height: 610px;
  }
  .feature-main-text {
    color: #e51a4b;
    font-size: 70px;
    font-family: 'Swis721 Cn BT';
    font-weight: bold;
    /* margin-top: 62%; */
    margin-top: 10%;
    margin-left: 00%;
    line-height: 70px;
    margin-bottom: 5%;
    display: inline-block;
    position: relative;
    left: 15%;
    text-align: right;
  }
  .feature-main-text span {
      font-size: 85px;
  }
  .featurehead-can-div {
      position: absolute;
      top: 70px;
      width: 100%;

  }
  .feature-head-can {
      height: 500px;
      margin: 0px auto;
      display: block;
  }










































@media (max-width: 991px) {
    .mobhide {
        display: none;
    }
    body {
        width: 100%;
        word-spacing: 1px;
    }
    .mobnav-height {
        height: 70px;
    }
    .navbar {
        width: 100%;
        background-color: #000 !important;
        color: #ffff00;
        padding: 0px;
        position: fixed;
        z-index: 1;
    }
    .nav-link {
        color: #ffff00 !important;
    font-family: 'Roboto';
    font-size: 16px;
    text-transform: uppercase;
    padding: 12px 0px;
    }
    .active {
        color: #c40632 !important;
    }
    .navbar-toggler:focus {
        box-shadow: unset;
    }
    .navbar .container-fluid {
        padding-left: 0px;
    }
    .main-logo {
        height: 70px;
    }
    .navbar-nav {
        padding: 20px;
    }
    .navbar-dark .navbar-toggler {
        position: relative;
    right: -5px;
    }
    .mainlogo.active {
        border-bottom: 0px;
    }
    .faq-page-head {
        font-size: 21px;
        margin: 5% 0%;
    }
    .faq-div {
        padding: 20px;
        padding-top: 0px;
    }
    .accordion-button {
        font-size: 13px;
    }
    .accordion-button:focus {
        box-shadow: unset;
    }
    .accordion-button:not(.collapsed) {
        font-size: 13px;
        padding: 10px 1rem;
    }
    .accordion-button {
        padding: 10px 1rem;
    }
    .accordion-button::after {
        width: 11px;
    height: 10px;
    }
    .accordion-body {
        font-size: 13px;
    }
    .caution-text br, .caution-text span {
        display: none;
    }
    .caution-text {
        padding: 0px 20px;
        font-size: 14px;
    }
    .footer-text br:not(:last-child) {
        display: none;
    }
    .footer-text {
        font-size: 14px;
    padding: 0px 9%;
    text-align: justify;
    text-align-last: center;
    }
    .footer-text span {
        display: block;
        margin-top: 10px;
    margin-bottom: 10px;
    }
    .footer-text .copyright-text {
        font-size: unset;
    }
    footer {
        padding: 22% 0%;
    }
    .section3bg-img {
        margin-right: 0px;
    }
    .section3bg-img img {
        width: 70%;
        float: right;
    position: relative;
    left: 30px;
    }
    .compare-bg {
        height: 315px;
    background-size: cover;
    background-position: center center;
    padding-top: 48%;
    border-radius: 10px;
    }
    section {
        padding: 16px;
        overflow: hidden;
    }
    .section1-bg {
        margin: 0px;
        background-position: center;
        background-position-x: -120px;
    }
    .section1-can {
        width: 85%;
        left: 0%;
        margin-top: 12%;        
    }
    .protect-yourself-head {
        font-size: 35px;
    margin-top: 200px;
    line-height: 40px;
    }
    .buy-btn {
        font-size: 13px;
    padding: 10px 20px 9px;
    }
    .section2bg {
        height: unset;
        background-image: none;
    }
    .sec2imgmob {
        width: 100%;
    }
    .section2-content {
        width: 100%;
        padding: 18px 0px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
    .section2-box {
        position: unset;
        margin-top: -5px;
    }
    .box-head {
        font-size: 18px;
    }
    .box-text {
        font-size: 12px;
        margin-bottom: 0px;
        margin-top: 5px;
        padding: 0px 15px;
    }
    .box-text br {
        display: none;
    }
    .section3bg {
        position: relative;
    }
    .section3bg .section-text a {
        position: absolute;
        top: 32%;
        left: 6%;
    }
    .section-text {
        margin-left: 0px;
        margin-top: 5%;
    }
    .section-text p br {
        display: none;
    }
    .section-text p {
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        margin-bottom: 5px;
    }
    .uptext br {
        /* display: none; */
    }
    .uptext {
        /* padding: 0px 30px; */
        font-size: 11px;
        line-height: 14px;
    }
    .downtext br {
        /* display: none; */
    }
    .downtext {
        font-size: 12px;
        line-height: unset;
        margin-top: -10px;
    }
    .greybg {
        background-image: unset;
        margin-top: 5%;
    padding-bottom: 0%;
    }
    .grey-row {
        width: 100%;
        bottom: 0px;
    }
    .bag-img {
        width: 60%;
        margin: 0px auto;
        display: block;
        margin-bottom: 8%;
    }
    .grey-head br {
        display: none;
    }
    .grey-head {
        font-size: 21px;
        text-align: center;
    }
    .grey-textarea {
        margin-top: 0%;
    }
    .grey-text br {
        display: none;
    }
    .grey-text {
        font-size: 14px;
        margin-left: 0px;
        line-height: 20px;
        text-align: center;
        margin-top: 5px;
    }
    .greysection {
        padding-left: 16px;
    }
    .usage-section1-img img {
        width: 70%;
        margin: 0px auto;
        display: block;
        bottom: unset;
        right: unset;
        float: unset;
    } 
    .usage-section1bg {
        height: unset;
        margin: 0% 0%;
        /* background-image: url('./../images/mobusagebg.png'); */
        background-image: url('./../images/newusagebg.png');
        padding: 0% 0% 8%;
        background-position-y: -3.5rem;
    }
    .usage-section1-head {
        text-align: center;
        margin-left: 0px;
        margin-top: 50px;
        font-size: 25px;
    }
    .usage-section1-text br {
        display: none;
    }
    .usage-section1-text {
        margin-left: 0px;
        font-size: 14px;
        text-align: center;
        padding: 0px 25px;
        text-align: justify;
        text-align-last: center;
        line-height: 19px;
    }
    .usage-icon {
        position: absolute;
        left: 50px;
        height: 70px;
    }
    .icon-text {
        margin-left: 15%;
        margin-right: -25%;
        font-size: 15px;
        height: 70px;
        margin-bottom: 0px;
    }
    .icon-text br {
        display: none;
    }
    .user-guidance-head {
        font-size: 25px;
        margin-bottom: 8%;
    }
     .guidance-row .mt-5 {
        margin-top: 0px !important;
        margin-bottom: 60px;
    }
    .guidance-row .mt-4 {
        margin-bottom: 40px;
    }
    .guidance-row .mt-4:first-child {
        margin-bottom: 20px;
    }
    
    .usage-section2bg {
        padding-top: 8%;
        padding-bottom: 40%;
    }
    .usage-midbannerbg {
        height: 600px;
        background-position: bottom right;
        background-position-x: 70%;
        background-size: inherit;
    }
    .usage-blackbox {
        width: 75%;
        padding: 15px;
    }
    .usage-blackbox-head {
        font-size: 25px;
    }
    .usage-blackbox-text {
        font-size: 15px;
    }
    .usage-blackbox-text br {
        display: none;
    }
    .usage-midbanner-div {
        left: 15px;
        bottom: 15px;
    }
    .feature-row {
        width: 90%;
    }
    .featurehead {
        font-size: 14px;
        line-height: 18px;
    }
    .featurehead br {
        display: none;
    }
    .featuretext {
        font-size: 12px;
        line-height: 14px;
        margin-top: 5px;
    }
    .featuretext br {
        display: none;
    }
    .feature-width-set {
        width: 95%;
    }
    .feature-row .row {
        margin-bottom: 15px;
    } 
    .accordion-item {
        border-bottom: 1px solid #959595;
    }
    .enquiry-head {
        margin-left: 0px;
        text-align: center;
        font-size: 21px;
        line-height: 29px;
    }
    .enquiry-info-div {
        margin-left: 0px;
        margin-top: 25px;
    }
    .phoneicon {
        width: 30px;
    }
    .phoneicon-text {
        margin-left: -20px;
        top: 3px;
    }
    .fb-div {
        text-align: center;
    }
    .follow-head {
        text-align: center;
    }
    .touch-head br {
        display: none;
    }
    .touch-head {
        margin-top: 10%;
    }
    .fb-icon {
        margin-left: 5px;
        margin-right: 5px;
    }
    .form-div {
        width: 80%;
    margin: 0px auto;
    }
    .enquiry-input {
        border-radius: 10px;
    }
    .enquire-btn {
        border-radius: 10px;
    }
    .enquiry-info-div .row {
        position: relative;
        left: 20%;
    }
    .follow-head {
        margin-top: 25px;
        font-size: 12px;
    }
    .Buy-btn-div .buy-btn {
        padding: 10px 20px 9px;
    }
    .buy-head-img {
        width: 270px;
    }
    .Buy-btn-div {
        position: absolute;
    left: 30px;
    top: 37%;
    }
    .buy-section1-info-div {
        margin-left: 0px;
    }
    .buy-section1-head {
        font-size: 21px;
        text-align: center;
        line-height: 25px;
    }
    .buy-section1 .row .col-12 {
        position: relative;
    }
    .buy-section1-info {
        text-align: center;
        font-size: 13px;
        line-height: 17px;
    }
    .buy-section1-desc br {
        display: none;
    }
    .buy-section1-desc {
        font-size: 14px;
        line-height: unset;
        text-align: center;
    }
    .avaliable-div {
        margin-top: 10px;
    }
    .amazondiv {
        text-align: center;
    }
    .buy-feature {
        width: 95%;
    margin: 0px auto;
    margin-top: 20px;
    }
    .buy-feature-text {
        font-size: 12px;
    }
    .buy-section2bg .feature-margin-top {
        margin-top: 0px;
    }
    .buy-section2bg {
        padding: 8% 8% 12%;
    }
    .video-bg {
        background-image: unset;
        height: unset;
        margin: 0px;
    }
    .buyvideo-img {
        width: 100%;
    }
    .buy-subhead {
        font-size: 21px;
        text-align: center;
    }
    .buy-subhead-text {
        font-size: 14px;
        text-align: justify;
        padding: 0px 20px;
        text-align-last: center;
        line-height: 19px;
    }
    .mob-buy-usediv-row {
        width: 90%;
        margin: 0px auto;
        display: block;
        margin-top: 0px;
    }
    .icon01-text {
        font-size: 10px;
        line-height: 13px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 90%;
        left: 5px;
    }
    .icon01 {
        width: 90%;
        margin: 0px auto;
        display: block;
        right: 0px;
    }
    .icon02-text {
        left: 5px;
    }
    .icon01-text br {
        display: none;
    }
    .icon01 {
        bottom: 0px;
    }
    .mob-buy-usediv-row  .col-6 {
        margin-top: 20px;
    }
    .buy-midboxrow {
        width: 90%;
        margin: 0px auto;
        display: block;
    }
    .feature01 {
        height: 65%;
        margin: 0px auto;
        display: block;
    }
    .feature01-text {
        font-size: 11px;
        line-height: 15px;
        margin-top: 0px;
    }
    .buy-midboxrow .col-4 {
        margin: 10px 0px;
    }
    .mob-storagerow {
        padding: 0% 0%;
    }
    .storage-text {
        font-size: 14px;
        line-height: 18px;
        margin-top: 5px;
    }
    .storage-img-div {
        height: 50px;
        margin-top: 15px;
    }
    .navbar-toggler input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -2px;
  left: 7px;
  
  cursor: pointer;
  
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  
  /* -webkit-touch-callout: none; */
}
    .navbar-toggler .icon-bar {
        display: block;
        width: 32px;
        height: 3px;
        margin-bottom: 5px;
        position: relative;  
        background: #e51a4b;
        border-radius: 0px;  
        z-index: 1;  
        transform-origin: 4px 0px;
  
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
    }
    .navbar-toggler span:first-child
{
  transform-origin: 0% 0%;
}

.navbar-toggler span:nth-last-child(2)
{
  transform-origin: 0% 100%;
}

.navbar-toggler[aria-expanded="true"]  span
{
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -14px);
  background: #e51a4b;
  width: 25px;
  height: 2px;
}

.navbar-toggler[aria-expanded="true"]   span:nth-last-child(3)
{
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

.navbar-toggler[aria-expanded="true"]   span:nth-last-child(2)
{
  transform: rotate(-43deg) translate(0, 13px);
}
    .navbar-dark .navbar-toggler {
        color: unset;
        border: unset;
    }
    .enquiry-righ-brd {
        border-right: 0px;
    }
    .enquiry-bg {
        overflow-x: hidden;
    }
    .accordion-button:hover {
        z-index: unset;
    }

    .feature-main-text {
        font-size: 30px;
        line-height: 35px;
        left: 0%;
        text-align: center;
        margin-top: 8%;
        margin-bottom: 0%;
        width: 100%;
    }
    .feature-main-text span {
        font-size: 30px;
    }
    /* .feature-main-text br {
        display: none;
    } */
    .feature-head-can {
        height: 200px;
        margin: unset;
        left: 40px;
        position: relative;
    }
    .featuretopleft {
        width: 100%;
        height: unset;
        float: unset;
    }
    .featurehead-can-div {
        top: unset;
        bottom: 10px;
    }
    .featurebg-main {
        height: unset;
    }
   
    
}

@media (min-width: 540px) and (max-width: 720px) {

}

@media (min-width: 721px) and (max-width: 991px) {
    .section1-bg {
        background-position-x: 0px;
    }
    .section1-can {
        width: 50%;
    left: 22%;
    }
    .main-logo {
        height: 120px;
        width: unset;
    }
    .mobnav-height {
        height: 120px;
    }
    .navbar-toggler .icon-bar {
        width: 50px;
    height: 6px;
    }
    .nav-link {
        font-size: 24px;
        padding: 16px 0px;
    }
    .protect-yourself-head {
        font-size: 55px;
        margin-top: 250px;
        line-height: 65px;
        margin-left: 5%;
    }
    .buy-btn {
        font-size: 21px;
        padding: 17px 32px 16px;
        margin-left: 5%;
        top: 5px;
        position: relative;
    }
    .box-head {
        font-size: 32px;
    }
    .box-text {
        font-size: 18px;
        margin-bottom: 0px;
        margin-top: 5px;
        width: 70%;
        margin: 0px auto;
    }
    .section2-content {
        padding: 30px 0px;
    }
    .section2-box {
        margin-top: -13px;
    }
    .section3bg-img {
        float: unset;
    }
    .section3bg-img img {
        width: 60%;
    }
    .section3bg .section-text a {
        top: 36%;
        left: 12%;
    }
    .section-text p {
        line-height: 25px;
        font-size: 21px;
        width: 80%;
    margin: 0px auto;
    margin-bottom: 10px;
    }
    .compare-bg {
        height: unset;
        padding: 25% 0% 10%;
    }
    .uptext {
        font-size: 18px;
    }
    .uptext {
        font-size: 18px;
        line-height: 22px;
    }
    .downtext {
        font-size: 20px;
        line-height: 25px;
        margin-top: 0px;
    }
    .compare-text-box {
        position: relative;
        top: 35px;
    }
    .grey-row {
        width: 80%;
        bottom: 0px;
    }
    .bag-img {
        width: 90%;
    }
    .grey-head {
        font-size: 30px;
        margin-top: 15px;
    }
    .grey-text {
        font-size: 18px;
        line-height: 22px;
        margin-top: 10px;
    }
    .caution-text {
        font-size: 20px;
    }
    .caution-head {
        font-size: 30px;
    }
    .greybg {
        padding-bottom: 5%;
    }
    footer {
        padding: 10% 0%;
    }
    .footer-text {
        font-size: 20px;
        padding: 0px 10%;
    }
    .footer-text span {
        font-size: 28px;
        margin-top: 15px;
    }
    .upl-logo {
        width: 130px;
    }
    .usage-section1bg {
        background-position-y: -10.5rem;
    }
    .usage-section1-img img {
        width: 45%;
    }
    .usage-section1-head {
        font-size: 35px;
    }
    .usage-section1-text {
        font-size: 18px;
        line-height: unset;
        padding: 0px 50px;
    }
    .guidance-row {
        width: 80%;
    }
    .guidance-row .row {
        position: relative;
    }
    .icon-text {
        margin-left: 0%;
        margin-right: unset;
        font-size: 18px;
        height: 100px;
        line-height: 22px;
        left: 0px;
        padding-right: 15px;
    }
    .usage-icon {
        left: -30px;
        position: relative;
        /* width: 100%; */
    }
    .usage-section2bg {
        padding-bottom: 22%;
    }
    .usage-blackbox-text {
        font-size: 18px;
        line-height: 22px;
    }
    .usage-blackbox-head {
        font-size: 35px;
    }
    .usage-blackbox {
        padding: 20px;
        width: 60%;
    }
    .usage-midbanner-div {
        left: 20px;
        bottom: 20px;
    }
    .featurehead {
        font-size: 24px;
        line-height: unset;
    }
    .featuretext {
        font-size: 18px;
        line-height: 22px;
    }
    .feature-row .row {
        margin-bottom: 0px;
    }
    .navbar-dark .navbar-toggler {
        right: 0px;
    }
    .faq-page-head {
        font-size: 35px;
    }
    .accordion-button:not(.collapsed), .accordion-button {
        font-size: 16px;
        padding: 14px 1rem;
    }
    .accordion-body {
        font-size: 15px;
    }
    .enquiry-head {
        font-size: 28px;
    line-height: 35px;
    }
    .phoneicon {
        width: 35px;
    }
    .phoneicon-text {
        font-size: 16px;
        margin-left: -10px;
    }
    .enquiry-head {
        margin-top: 0px;
    }
    .enquiry-head br {
        display: block;
    }
    .enquiry-info-div .mt-2 {
        margin-top: 10px !important;
    }
    .enquiry-head {
        text-align: left;
    }
    .enquiry-info-div .row {
        left: 0px;
    }
    .follow-head {
        text-align: left;
    }
    .fb-div {
        text-align: left;
    }
    .enquiry-bg {
        padding: 7%;
    }
    .touch-head {
        margin-left: 10%;
    }
    .fb-icon {
        margin-left: 0px;
        margin-right: 10px;
    }
    .form-div {
        width: 90%;
        margin-left: 10%;
    }
    ::placeholder {
        font-size: 10px;
        left: 0px;
        bottom: 2px;
    }
    .enquiry-righ-brd {
        border-right: 2px solid #959595;
    }
    .buy-head-img {
        width: 60%;
    }
    .Buy-btn-div {
        width: 100%;
        left: 15%;
    }
    .Buy-btn-div .buy-btn {
        padding: 18px 30px 17px;
    }
    .buy-section1-head {
        font-size: 28px;
        line-height: 35px;
        margin-top: 3%;
    }
    .buy-section1-info {
        font-size: 18px;
        line-height: 24px;
        margin-top: 3%;
    }
    .buy-section1-desc {
        font-size: 20px;
    }
    .amazonimg {
        width: 110px;
        margin-bottom: 15px;
        margin-right: 15px;
    }
    .buy-feature {
        width: 75%;
        margin: 0px auto;
        margin-top: 30px;
    }
    .buy-feature-text {
        font-size: 20px;
    }
    .buy-subhead {
        font-size: 24px;
        margin-top: 3%;
    }
    .buy-subhead-text {
        font-size: 18px;
        line-height: 24px;
    }
    .icon01 {
        width: 65%;
    }
    .icon01-text {
        font-size: 16px;
    line-height: 20px;
    }
    .buy-midboxbg {
        margin-top: 2%;
    }
    .image-div {
        height: 100px;
    }
    .feature01-text {
        font-size: 16px;
    line-height: 21px;
    }
    .storage1 {
        width: 30%;
    }
    .storage-img-div {
        height: 70px;
        margin-top: 30px;
    }
    .storage-text {
        font-size: 16px;
    line-height: 20px;
    }
    .feature-main-text {
        font-size: 55px;
        line-height: 60px;
    }
    .feature-head-can {
        height: 450px;
        left: 80px;
    }
}

@media (min-width: 992px) {
    .deskhide {
        display: none;
    }
}

/* @media (min-width: 991px) and (max-width: 1023px) {
    .main-navbar .nav-link {
        padding-right: 20px !important;
    padding-left: 20px !important;
}
.main-navbar .nav-item {
    padding-left: 10px;
    padding-right: 10px;
}
    } */

